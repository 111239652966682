import React, { useState, useEffect } from 'react';
import axiosInstance from "../../utils/axiosInstance";

import sendBlack from '../../asset/comments/send_black.svg';
import sendWhite from '../../asset/comments/send_white.svg';

function Google() {
    const [googleEmail, setGoogleEmail] = useState('prajwal@tiramisumedia.com');

    const googleLoginFn = async () => {
        let prmData = {
            username: googleEmail,
        };
        try {
            const response = await axiosInstance.post('CompanyUser/select', prmData);
            console.log(response);
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    return (
        <div className="loginCard registrationCard">
            <input type="text" className="inputField" placeholder="Google ID" onChange={(e) => setGoogleEmail(e.target.value)} />

            <div className="flex justify-between items-center">
                <h5 className="font-300"></h5>
                <div onClick={googleLoginFn}>
                    <img src={sendWhite} alt="" className="darkMode" />
                    <img src={sendBlack} alt="" className="lightMode" />
                </div>
            </div>
        </div>
    );
}

export default Google;
