import React, { useState, useRef, useEffect } from 'react';
import HoverIcon from "../../utils/HoverIcon";

import searchBlack from '../../asset/search_black.svg';
import searchWhite from '../../asset/search_white.svg';
import sendBlack from '../../asset/comments/send_black.svg';
import sendWhite from '../../asset/comments/send_white.svg';
import pencilBlack from '../../asset/header/pencil_black.svg';
import pencilWhite from '../../asset/header/pencil_white.svg';
import pencilBlue from '../../asset/header/pencil_blue.svg';
import tieBlack from '../../asset/header/tie_black.svg';
import tieWhite from '../../asset/header/tie_white.svg';
import tieBlue from '../../asset/header/tie_blue.svg';

import gasBlack from '../../asset/header/gas_black.svg';
import gasWhite from '../../asset/header/gas_white.svg';
import gasYellow from '../../asset/header/gas_yellow.svg';

function MobileFooter({ setShowMyBunch, showMyBunch }) {
    const [showEdit, setShowEdit] = useState(false);
    const [showBuyVotes, setShowBuyVotes] = useState(false);

    const toggleMyBunchPopup = () => {
        setShowMyBunch(!showMyBunch);
    };
    const toggleBuyVotesPopup = () => {
        setShowBuyVotes(!showBuyVotes);
    };
    const toggleEditPopup = () => {
        setShowEdit(!showEdit);
    };

    return (
        <div className='flex sm:hidden fixed bottom-4 w-full justify-between bg-white dark:bg-black'>
            <div className='cursor-pointer' onClick={toggleMyBunchPopup}>
                <HoverIcon
                    defaultIconBlack={tieBlack}
                    defaultIconWhite={tieWhite}
                    activeIcon={tieBlue}
                    altText=""
                />
            </div>
            <div className=''>
                <div className='cursor-pointer flex' onClick={toggleBuyVotesPopup}>
                    <img src={gasBlack} alt="" className="lightMode" />
                    <img src={gasWhite} alt="" className="darkMode" />
                </div>
            </div>
            <div className='cursor-pointer' onClick={toggleEditPopup}>
                <HoverIcon
                    defaultIconBlack={pencilBlack}
                    defaultIconWhite={pencilWhite}
                    activeIcon={pencilBlue}
                    altText=""
                />
            </div>

            {showBuyVotes &&
                <div className="absolute right-0 bottom-24 w-full flex justify-between items-center p-[10px_15px_10px_5px] bg-[#E5E5EF] dark:bg-[#000] dark:border">
                    <h4 className="font-700">87 Votes remain</h4>
                    <button className="btnBlue w-[73px]">Buy more</button>
                </div>}

            {showEdit &&
                <div className="contactCard absolute right-0 z-10 w-full bottom-[42px]">
                    <div className="relative">
                        <input type="text" className="inputField !pl-[25px] w-full" placeholder="Whom?" />
                        <div className="absolute top-[4px] left-[5px]">
                            <img src={searchWhite} alt="" className="darkMode" />
                            <img src={searchBlack} alt="" className="lightMode" />
                        </div>
                    </div>
                    <input type="text" className="inputField" placeholder="Post title max 250 chars" />
                    <input type="text" className="inputField" placeholder="URL" />

                    <div className="flex justify-between items-center">
                        <h5 className="font-300"></h5>
                        <img src={sendWhite} alt="" className="darkMode" />
                        <img src={sendBlack} alt="" className="lightMode" />
                    </div>
                </div>}
        </div>
    );
}

export default MobileFooter;
