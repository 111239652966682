import React, { useState, useEffect, useRef } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const BubbleBlue = ({ data }) => {
    const chartRef = useRef(null);

    const [isDarkMode, setIsDarkMode] = useState(false);

    useEffect(() => {
        const checkDarkMode = () => {
            setIsDarkMode(window.matchMedia('(prefers-color-scheme: dark)').matches);
        };
        checkDarkMode();
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', checkDarkMode);

        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', checkDarkMode);
        };
    }, []);

    const options = {
        chart: {
            type: 'bubble',
            plotBorderWidth: 0,
            zoomType: 'xy',
            backgroundColor: isDarkMode ? '#000' : '#FFF',
            width: 40,
            height: 40,
            events: {
                render: function () {
                    const chart = this;
                    // Remove previously rendered shapes to avoid duplication.
                    if (chart.customRoundedBands) {
                        chart.customRoundedBands.forEach(shape => shape.destroy());
                    }
                    chart.customRoundedBands = [
                        // Add rounded rectangle for positive x-axis band
                        chart.renderer
                            .rect(
                                chart.plotLeft + chart.xAxis[0].toPixels(1, true),
                                chart.plotTop,
                                chart.xAxis[0].toPixels(100, true) - chart.xAxis[0].toPixels(1, true),
                                chart.plotHeight,
                                2 // border radius for rounded corners
                            )
                            .attr({
                                fill: '#4169E1',
                                zIndex: -1 // Ensure this is behind the plot lines
                            })
                            .add(),
                        // Add rounded rectangle for negative x-axis band
                        chart.renderer
                            .rect(
                                chart.plotLeft + chart.xAxis[0].toPixels(-100, true),
                                chart.plotTop,
                                chart.xAxis[0].toPixels(-1, true) - chart.xAxis[0].toPixels(-100, true),
                                chart.plotHeight,
                                2 // border radius for rounded corners
                            )
                            .attr({
                                fill: '#4169E1',
                                zIndex: -1 // Ensure this is behind the plot lines
                            })
                            .add()
                    ];
                }
            }
        },
        title: {
            text: ''
        },
        credits: {
            enabled: false,
        },
        tooltip: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            gridLineWidth: 0,
            min: -100,
            max: 100,
            title: {
                text: null
            },
            labels: {
                enabled: false
            },
            tickLength: 0,
            plotLines: [
                {
                    color: "#FFF",
                    dashStyle: "line",
                    width: 2,
                    value: 0,
                    zIndex: 3 // Ensure this is above the plot bands
                },
            ],
            lineColor: isDarkMode ? '#000' : '#fff',
        },
        yAxis: {
            gridLineWidth: 0,
            min: -100,
            max: 100,
            title: {
                text: null
            },
            labels: {
                enabled: false
            },
            plotLines: [
                {
                    color: "#FFF",
                    dashStyle: "line",
                    width: 2,
                    value: 0,
                    zIndex: 3 // Ensure this is above the plot bands
                },
            ],
        },
        series: [{
            data: data.map(point => ({
                x: point.self,
                y: point.society,
                z: 1,
                color: point.color,
                marker: {
                    lineColor: point.borderColor,
                    lineWidth: 2,
                }
            })),
            dataLabels: {
                enabled: false // Hide data labels
            },
            marker: {
                fillOpacity: 1
            }
        }]
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            ref={chartRef}
        />
    );
};

export default BubbleBlue;
