import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'universal-cookie';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
    const cookies = new Cookies();
    const [token, setToken] = useState(cookies.get('token') || '');
    const [userDetails, setUserDetails] = useState(() => {
        const storedDetails = cookies.get('userDetails');
        return storedDetails ? storedDetails : null; // Retrieve from cookies on initial load
    });

    const isAuthenticated = !!token; // Determine if authenticated based on token

    const login = (token, userDetails) => {
        cookies.set('token', token, { path: '/', secure: true, sameSite: 'strict' });
        cookies.set('userDetails', JSON.stringify(userDetails), { path: '/', secure: true, sameSite: 'strict' });
        setToken(token);
        setUserDetails(userDetails);
    };

    const logout = () => {
        cookies.remove('token', { path: '/' });
        cookies.remove('userDetails', { path: '/' });
        setToken('');
        setUserDetails(null);
    };

    const setUser = (details) => {
        cookies.set('userDetails', JSON.stringify(details), { path: '/', secure: true, sameSite: 'strict' });
        setUserDetails(details);
    };

    return (
        <AuthContext.Provider value={{ token, userDetails, isAuthenticated, login, logout, setUser }}>
            {children}
        </AuthContext.Provider>
    );
};
