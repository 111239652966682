import React, { useState } from 'react';
import searchBlack from '../../asset/search_black.svg';
import searchWhite from '../../asset/search_white.svg';
import sendBlack from '../../asset/comments/send_black.svg';
import sendWhite from '../../asset/comments/send_white.svg';

function MyBunch() {
    const [showSearch, setShowSearch] = useState(false);
    const [addBunch, setAddBunch] = useState(false);

    const toggleSearchPopup = () => {
        setShowSearch(!showSearch);
        setAddBunch(!addBunch);
    };
    const getActiveState = () => {
        if (addBunch) {
            return 'active';
        }
        else {
            return '';
        }
    };
    return (
        <div className="rounded-[5px] bg-[#E5E5EF] dark:bg-[#FFFFFF1A]">
            <div className='flex items-center gap-[10px]'>
                <div className='p-[0px_10px]'>
                    <h1>My Bunch</h1>
                </div>
                <button className={`addButton ${getActiveState()} w-[48px] h-[22px]`} onClick={toggleSearchPopup}>Add</button>
            </div>
            {
                showSearch &&
                <div className='absolute top-[28px] left-[0px] right-[0px] m-auto w-[96%] bg-white p-[20px_5px]'>
                    <div className='relative'>
                        <input type="text" className="whomField" placeholder="" />
                        <div className="absolute top-[4px] left-[6px]">
                            <img src={searchWhite} alt="" className="darkMode" />
                            <img src={searchBlack} alt="" className="lightMode" />
                        </div>
                        <button className={`addButton h-[22px] p-[0px_15px] absolute top-[3px] right-[6px]`} onClick={toggleSearchPopup}>Add new</button>
                        <div>
                            <h5>No results. Add new</h5>
                        </div>
                        <div className="loginCard registrationCard">
                            <input type="text" className="inputField" placeholder="Name" />
                            <input type="text" className="inputField" placeholder="Verification URL" />
                            <h4>Note: Allow us 2 days to review.</h4>

                            <div className="flex justify-between items-center">
                                <h5 className="font-300"></h5>
                                <img src={sendWhite} alt="" className="darkMode" />
                                <img src={sendBlack} alt="" className="lightMode" />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default MyBunch;
