import React, { useState } from 'react';
import { useAuth } from './AuthContext';

import apple from '../assets/login/apple.svg';
import facebook from '../assets/login/facebook.svg';
import google from '../assets/login/google.svg';
import mail from '../assets/login/mail.svg';

const LoginModal = ({ isOpen, onClose }) => {
  // console.log(isOpen, onClose);
  const { setIsAuthenticated } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = () => {
    // Add your login logic here
    // For now, we'll assume a successful login if both fields are filled
    if (username && password) {
      setIsAuthenticated(true);
      onClose(); // Close the modal after login
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal">
      <div className='dialogClass gap-[10px]'>
        <h3 className='font-700'>
          {/* {heading} */}
        </h3>
        <div className='flex items-center gap-1 cursor-pointer'>
          <img src={google} alt="google" />
          <h3 className='font-400'>Login with Google</h3>
        </div>
        <div className='flex items-center gap-1 cursor-pointer'>
          <img src={apple} alt="apple" className='w-[20px]' />
          <h3 className='font-400'>Login with Apple</h3>
        </div>
        <div className='flex items-center gap-1 cursor-pointer'>
          <img src={facebook} alt="facebook" />
          <h3 className='font-400'>Login with Facebook</h3>
        </div>
        <div className='flex items-center gap-1 cursor-pointer'>
          <img src={mail} alt="mail" />
          <h3 className='font-400'>Create new</h3>
        </div>
        <div className='flex items-center gap-1 cursor-pointer'>
          <img src={mail} alt="mail" />
          <h3 className='font-400'>Login</h3>
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
