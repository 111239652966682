import React, { useState, useRef, useEffect } from 'react';
import HoverIcon from "..//utils/HoverIcon";

import searchBlack from '../asset/search_black.svg';
import searchWhite from '../asset/search_white.svg';
import sendBlack from '../asset/comments/send_black.svg';
import sendWhite from '../asset/comments/send_white.svg';
import pencilBlack from '../asset/header/pencil_black.svg';
import pencilWhite from '../asset/header/pencil_white.svg';
import pencilBlue from '../asset/header/pencil_blue.svg';
import tieBlack from '../asset/header/tie_black.svg';
import tieWhite from '../asset/header/tie_white.svg';
import tieBlue from '../asset/header/tie_blue.svg';

import gasBlack from '../asset/header/gas_black.svg';
import gasWhite from '../asset/header/gas_white.svg';
import gasYellow from '../asset/header/gas_yellow.svg';

function Footer() {
  const [showProfile, setShowProfile] = useState(false);
  const [showBuyVotes, setShowBuyVotes] = useState(false);
  const [showMyBunch, setShowMyBunch] = useState(false);

  const toggleProfilePopup = () => {
    setShowProfile(!showProfile);
  };
  const toggleBuyVotesPopup = () => {
    setShowBuyVotes(!showBuyVotes);
  };
  const toggleMyBunchPopup = () => {
    setShowMyBunch(!showMyBunch);
  };
  return (
    <footer>
      
    </footer>
  );
}

export default Footer;
