// store.js
import { configureStore } from '@reduxjs/toolkit';
import dialogReducer from './reducer';

const store = configureStore({
    reducer: {
      dialog: dialogReducer, // Register your reducer
    },
  });

export default store;
