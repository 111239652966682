import React, { useState } from 'react';
import axiosInstance from "../../utils/axiosInstance";
import { useAuth } from '../AuthContext';

function AddPersona() {
    const { userDetails } = useAuth();
    const [file, setFile] = useState('');
    const [previewUrl, setPreviewUrl] = useState('');

    const [personaValues, setPersonaValues] = useState({
        personaName: '',
        personaPosition: '',
        fromDate: '',
        toDate: '',
    });

    const handlePersonaInput = (event) => {
        const { name, value } = event.target;
        setPersonaValues({
            ...personaValues,
            [name]: value
        });
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        const maxSizeMB = 2; // Max size in MB
        const maxSizeBytes = maxSizeMB * 1024 * 1024; // Convert MB to bytes

        if (selectedFile) {
            if (selectedFile.size > maxSizeBytes) {
                alert(`File size exceeds ${maxSizeMB}MB. Please select a smaller file.`);
                return;
            }
            setFile(selectedFile);
            const preview = URL.createObjectURL(selectedFile);
            setPreviewUrl(preview);
        } else {
            console.log("No file selected");
        }
    };

    const imageUpload = async () => {
        if (!file) {
            alert("Please select image");
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        try {
            const response = await axiosInstance.post('fileupload/upload', formData, { headers });
            if (response.status === 200) {
                let data = response.data;
                return data.fileName;
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const addPersonaDetails = async () => {
        const uploadedImageName = await imageUpload();

        if (uploadedImageName) {
            let prmData = {
                personaImage: uploadedImageName,
                personaName: personaValues.personaName,
                personaPosition: personaValues.personaPosition,
                fromDate: personaValues.fromDate,
                toDate: personaValues.toDate,
                verificationLevelTagUniqueId: 1,
                logUserUniqueId: userDetails.companyUserUniqueId,
            };
            try {
                const response = await axiosInstance.post('persona/insert', prmData);
                alert("Successfully added");
                document.getElementById('fileInput').value = '';
                setFile('');
                setPreviewUrl('');
                setPersonaValues({
                    personaName: '',
                    personaPosition: '',
                    fromDate: '',
                    toDate: '',
                });
            } catch (error) {
                console.error('There was an error making the request!', error);
            }
        }
    };

    return (
        <div>
            <div className='w-[40%] m-auto flex flex-col gap-2'>
                <div>Add Persona</div>
                <div>
                    <input type="file" accept="image/jpeg, image/png" id="fileInput" onChange={handleFileChange} />
                    {previewUrl && <img src={previewUrl} alt="Image Preview" className="w-[100px]" />}
                </div>
                <input
                    type="text"
                    name="personaName"
                    value={personaValues.personaName}
                    onChange={handlePersonaInput}
                    placeholder='Persona Name'
                    className="testField" />
                <input
                    type="text"
                    name="personaPosition"
                    value={personaValues.personaPosition}
                    onChange={handlePersonaInput}
                    placeholder='Persona Position'
                    className="testField" />
                <input
                    type="text"
                    name="fromDate"
                    value={personaValues.fromDate}
                    onChange={handlePersonaInput}
                    placeholder='From Date'
                    className="testField" />
                <input
                    type="text"
                    name="toDate"
                    value={personaValues.toDate}
                    onChange={handlePersonaInput}
                    placeholder='To Date'
                    className="testField" />
                <button className='btn-custom bg-blue-100' onClick={addPersonaDetails}>Submit</button>
            </div>
        </div>
    );
}

export default AddPersona;
