import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './components/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import LoginModal from './components/LoginModal';

const ProtectedRoute = ({ children }) => {
  const location = useLocation(); // Get the current location
  const navigate = useNavigate(); // For navigating
  const { isAuthenticated } = useAuth();
  const [isLoginModalOpen, setLoginModalOpen] = useState(false);

  useEffect(() => {
    if (!isAuthenticated) {
      setLoginModalOpen(true); // Open the login modal if the user is not authenticated
      alert('Not allowed, please log in!');
    }
    console.log(isLoginModalOpen);
  }, [isAuthenticated]);

  if (isAuthenticated) {
    return children;
  }

  return (
    <div>
      {/* If not authenticated, show login modal */}
      <LoginModal
        isOpen={true}
        onClose={() => setLoginModalOpen(false)}
      />
      {/* Redirect to home if not authenticated */}
      {!isLoginModalOpen && <Navigate to="/" />}
    </div>
  );
};

export default ProtectedRoute;
