import React, { useState } from 'react';
import axiosInstance from "../../utils/axiosInstance";
import { useAuth } from '../../components/AuthContext';
import sendBlack from '../../asset/comments/send_black.svg';
import sendWhite from '../../asset/comments/send_white.svg';

function ReplyForm({ data, type, getCommentsList, getReplyList }) {
    const { userDetails } = useAuth();
    const [replyField, setReplyField] = useState('');
    const [loading, setLoading] = useState(false); // For loading state
    const [error, setError] = useState(null); // For error handling

    const submit = async () => {
        setLoading(true);
        setError(null);

        try {
            if (type === 'reply') {
                await addReply();
            } else {
                await addNestedReply();
            }
            setReplyField('');
        } catch (err) {
            setError('Failed to submit reply. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const addReply = async () => {
        const prmData = {
            postuniqueid: data.postuniqueid,
            parentpostcommentuniqueid: data.postcommentuniqueid,
            comments: replyField,
            companyuseruniqueid: userDetails.companyUserUniqueId,
        };
        await axiosInstance.post('reply/insert', prmData);
        await getCommentsList();
    };

    const addNestedReply = async () => {
        const prmData = {
            postuniqueid: data.postuniqueid,
            parentpostcommentuniqueid: data.postcommentreplyuniqueid,
            comments: replyField,
            companyuseruniqueid: userDetails.companyUserUniqueId,
        };
        await axiosInstance.post('reply/insert', prmData);
        await getReplyList();
    };

    return (
        <div className='reply-form'>
            <div className='commentField relative w-1/2 flex justify-start items-start ml-[20px]'>
                <div className='relative w-full'>
                    <input type="text" placeholder="Add a reply" value={replyField} onChange={(e) => setReplyField(e.target.value)} className='commentFieldCard h-[30px] w-full px-[15px] rounded-[2px] dark:bg-black' />
                    <div className='absolute top-0 bottom-0 right-4 m-auto inline-table' onClick={submit}>
                        <img src={sendWhite} alt="" className="darkMode" />
                        <img src={sendBlack} alt="" className="lightMode" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ReplyForm;
