import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';

HighchartsMore(Highcharts);

const BubbleChart = ({ data, type }) => {
  var dyWidth, dyHeight;
  if (type === 'personaComponent') {
    dyWidth = 75;
    dyHeight = 75;
  }
  else if (type === 'bigPlot') {
    dyWidth = 300;
    dyHeight = 358;
  }
  else {
    dyWidth = 100;
    dyHeight = 100;
  }
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const checkDarkMode = () => {
      setIsDarkMode(window.matchMedia('(prefers-color-scheme: dark)').matches);
    };
    checkDarkMode();
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', checkDarkMode);

    return () => {
      window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', checkDarkMode);
    };
  }, []);
  const options = {
    chart: {
      type: 'bubble',
      plotBorderWidth: 0,
      zoomType: 'xy',
      width: dyWidth,
      height: dyHeight,
      backgroundColor: isDarkMode ? '#000' : '#fff',
    },
    credits: {
      enabled: false, // Disable the credits
    },
    tooltip: {
      enabled: false, // Disable the tooltip
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
            width: 100,
            height: 100,
          },
          // chartOptions: {
          //   xAxis: {
          //     labels: {
          //       style: {
          //         fontSize: '10px', // Reduce label font size if needed
          //       },
          //     },
          //   },
          //   yAxis: {
          //     labels: {
          //       style: {
          //         fontSize: '10px', // Reduce label font size if needed
          //       },
          //     },
          //   },
          // },
        },
      ],
    },
    legend: {
      enabled: false,
    },
    title: {
      text: ''
    },
    xAxis: {
      gridLineWidth: 0,
      min: -10,
      max: 10,
      lineColor: isDarkMode ? '#000' : '#fff',
      tickLength: 0,
      labels: {
        enabled: false // Hide x-axis labels
      },
      title: {
        text: null // Hide x-axis title
      },
      plotLines: [
        {
          color: "#4169E1",
          dashStyle: "line",
          width: 2,
          value: 0,
        },
      ],
    },
    yAxis: {
      gridLineWidth: 0,
      startOnTick: false,
      endOnTick: false,
      min: -10,
      max: 10,
      labels: {
        enabled: false // Hide x-axis labels
      },
      title: {
        text: null // Hide x-axis title
      },
      plotLines: [
        {
          color: "#4169E1",
          dashStyle: "line",
          width: 2,
          value: 0,
        },
      ],
    },
    series: [{
      data: data.map(point => ({
        x: point.self,
        y: point.society,
        z: 50,
        color: point.color,
        marker: {
          lineColor: point.borderColor,
          lineWidth: 2,
        }
      })),
      dataLabels: {
        enabled: false // Hide data labels
      },
      marker: {
        fillOpacity: 1
      }
    }]
  };

  return (
    <div style={{ width: '100%', margin: '0 auto' }}>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default BubbleChart;
