import React, { useState, useEffect } from 'react';
import axiosInstance from "../../utils/axiosInstance";

import sendBlack from '../../asset/comments/send_black.svg';
import sendWhite from '../../asset/comments/send_white.svg';

function Signup({showStepTwo}) {
    const [email, setEmail] = useState('prajwal@tiramisumedia.com');
    const [password, setPassword] = useState('12345678');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const signUpFn = async () => {
        if (password !== confirmPassword) {
            setErrorMessage('Passwords do not match!');
        } else {
            setErrorMessage('');
            let prmData = {
                emailid: email,
                password: password,
            };
            try {
                const response = await axiosInstance.post('CompanyUser/insert', prmData);
                let data = response.data[0];
                localStorage.setItem('userID', data.userid);
                alert(data.returnvalue);
                localStorage.setItem('emailStorage', email);
                localStorage.setItem('passwordStorage', password);
                if (data.returntype) {
                    setEmail("");
                    setPassword("");
                    setConfirmPassword("");
                }
                showStepTwo();
            } catch (error) {
                console.error('There was an error making the request!', error);
            }
        }
    };
    
    return (
        <div className="registrationCard">
            <input type="text" className="inputField" placeholder="Email ID" onChange={(e) => setEmail(e.target.value)} />
            <input type="password" className="inputField" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            <input
                type="text"
                className="inputField"
                placeholder="Confirm Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <h4>by registering you agree to the terms and conditions</h4>

            <div className="flex justify-between items-center">
                <h5 className="font-300 cursor-pointer"></h5>
                <div onClick={signUpFn}>
                    <img src={sendWhite} alt="" className="darkMode" />
                    <img src={sendBlack} alt="" className="lightMode" />
                </div>
            </div>
            {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        </div>
    );
}

export default Signup;
