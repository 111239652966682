import React from 'react';
import apple from '../assets/login/apple.svg';
import facebook from '../assets/login/facebook.svg';
import google from '../assets/login/google.svg';
import mail from '../assets/login/mail.svg';
// 
import HoverIcon from "../utils/HoverIcon";
import replyIcon from '../assets/postDetails/replyIcon.svg';
import replyIconActive from '../assets/postDetails/replyIconActive.svg';
import burnIcon from '../assets/postDetails/burnIcon.svg';
import burnIconActive from '../assets/postDetails/burnIconActive.svg';
import upArrowIcon from '../assets/postDetails/upArrowIcon.svg';
import upArrowIconActive from '../assets/postDetails/upArrowIconActive.svg';
import downArrowIcon from '../assets/postDetails/downArrowIcon.svg';
import downArrowIconActive from '../assets/postDetails/downArrowIconActive.svg';
import moreIcon from '../assets/postDetails/moreIcon.svg';
import moreIconActive from '../assets/postDetails/moreIconActive.svg';

function Test() {
    return (
        <div className='bg-[#E5E5EF]'>
            <div className='dialogClass gap-[10px]'>
                <div className='flex items-center gap-1'>
                    <img src={google} alt="google" />
                    <h3 className='font-400'>Login with Google</h3>
                </div>
                <div className='flex items-center gap-1'>
                    <img src={apple} alt="apple" className='w-[20px]' />
                    <h3 className='font-400'>Login with Apple</h3>
                </div>
                <div className='flex items-center gap-1'>
                    <img src={facebook} alt="facebook" />
                    <h3 className='font-400'>Login with Facebook</h3>
                </div>
                <div className='flex items-center gap-1'>
                    <img src={mail} alt="mail" />
                    <h3 className='font-400'>Create new</h3>
                </div>
            </div>
            <div>
                <svg xmlns="http://www.w3.org/2000/svg" width="101" height="101" viewBox="0 0 101 101" fill="none">
                    <rect y="51" width="50" height="50" rx="7" fill="#4169E1" fill-opacity="0.5" />
                    <rect x="51" y="51" width="50" height="50" rx="7" fill="#4169E1" fill-opacity="0.5" />
                    <rect x="51" width="50" height="50" rx="7" fill="#4169E1" fill-opacity="0.5" />
                    <rect width="50" height="50" rx="7" fill="#4169E1" fill-opacity="0.5" />
                    <path d="M86 75C86 80.5228 81.5228 85 76 85C70.4772 85 66 80.5228 66 75C66 69.4772 70.4772 65 76 65C81.5228 65 86 69.4772 86 75Z" fill="#EEAD2B" />
                    <path d="M35 25C35 30.5228 30.5228 35 25 35C19.4772 35 15 30.5228 15 25C15 19.4772 19.4772 15 25 15C30.5228 15 35 19.4772 35 25Z" fill="#EEAD2B" />
                    <path d="M35 75C35 80.5228 30.5228 85 25 85C19.4772 85 15 80.5228 15 75C15 69.4772 19.4772 65 25 65C30.5228 65 35 69.4772 35 75Z" fill="#EE4B2B" />
                    <path d="M86 25C86 30.5228 81.5228 35 76 35C70.4772 35 66 30.5228 66 25C66 19.4772 70.4772 15 76 15C81.5228 15 86 19.4772 86 25Z" fill="#6DEE2B" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="70" height="70" viewBox="0 0 70 70" fill="none">
                    <path d="M32.0833 45.2083H36.4583V49.5833H32.0833V45.2083ZM35 20.2708C42.875 20.5917 46.2875 28.525 41.65 34.4458C40.425 35.9042 38.4708 36.8667 37.4792 38.1208C36.4583 39.375 36.4583 40.8333 36.4583 42.2917H32.0833C32.0833 39.8125 32.0833 37.7417 33.1042 36.2833C34.0667 34.825 36.0208 33.95 37.2458 32.9875C40.8333 29.6917 39.9 25.0542 35 24.675C32.6083 24.675 30.625 26.6292 30.625 29.0792H26.25C26.25 24.2083 30.1875 20.2708 35 20.2708ZM35 5.83333C33.5417 5.83333 32.0833 6.38749 30.8875 7.55416L7.55416 30.8875C5.24999 33.1625 5.24999 36.8375 7.55416 39.1125L30.8875 62.4458C33.1625 64.75 36.8375 64.75 39.1125 62.4458L62.4458 39.1125C64.75 36.8375 64.75 33.1625 62.4458 30.8875L39.1125 7.55416C37.9167 6.38749 36.4583 5.83333 35 5.83333ZM35 11.6667L58.3333 35L35 58.3333L11.6667 35L35 11.6667Z" fill="black" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="none">
                    <path d="M45.8333 64.5833H52.0833V70.8333H45.8333V64.5833ZM50 28.9583C61.25 29.4166 66.125 40.75 59.5 49.2083C57.75 51.2916 54.9583 52.6666 53.5417 54.4583C52.0833 56.25 52.0833 58.3333 52.0833 60.4166H45.8333C45.8333 56.875 45.8333 53.9166 47.2917 51.8333C48.6667 49.75 51.4583 48.5 53.2083 47.125C58.3333 42.4166 57 35.7916 50 35.25C46.5833 35.25 43.75 38.0416 43.75 41.5416H37.5C37.5 34.5833 43.125 28.9583 50 28.9583ZM50 8.33331C47.9166 8.33331 45.8333 9.12498 44.125 10.7916L10.7916 44.125C7.49998 47.375 7.49998 52.625 10.7916 55.875L44.125 89.2083C47.375 92.5 52.625 92.5 55.875 89.2083L89.2083 55.875C92.5 52.625 92.5 47.375 89.2083 44.125L55.875 10.7916C54.1666 9.12498 52.0833 8.33331 50 8.33331ZM50 16.6666L83.3333 50L50 83.3333L16.6666 50L50 16.6666Z" fill="black" />
                </svg>
            </div>
            <div>
                <HoverIcon
                    defaultIcon={replyIcon}
                    activeIcon={replyIconActive}
                    altText="Reply"
                />
                <HoverIcon
                    defaultIcon={burnIcon}
                    activeIcon={burnIconActive}
                    altText="Burn"
                />
                <HoverIcon
                    defaultIcon={upArrowIcon}
                    activeIcon={upArrowIconActive}
                    altText=""
                />
                <HoverIcon
                    defaultIcon={downArrowIcon}
                    activeIcon={downArrowIconActive}
                    altText=""
                />
                <HoverIcon
                    defaultIcon={moreIcon}
                    activeIcon={moreIconActive}
                    altText=""
                />
            </div>
            <div>
                <div className='bg-[#fff] rounded-[5px] py-[20px] px-[5px] flex flex-col gap-[10px]'>
                    <input type="text" className='inputField' placeholder='Email ID' />
                    <input type="text" className='inputField' placeholder='Password' />
                    <input type="text" className='inputField' placeholder='Confirm Password' />
                </div>
            </div>
        </div>
    );
}

export default Test;
