// actions.js
export const OPEN_DIALOG = 'OPEN_DIALOG';
export const CLOSE_DIALOG = 'CLOSE_DIALOG';

export const openDialog = () => ({
  type: OPEN_DIALOG,
});

export const closeDialog = () => ({
  type: CLOSE_DIALOG,
});
