// App.js
import './App.css';
import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import ProtectedRoute from './ProtectedRoute';
import Header from './components/Header';
import Footer from './components/Footer';
import PersonaDetails from './pages/PersonaDetails';
import PostDetails from './pages/PostDetails';
import Register from './pages/Register';
import Add from './pages/Add';
import Test from './pages/Test';
import { GlobalProvider } from './components/context/GlobalContext';

// Lazy load components
const Home = lazy(() => import('./pages/Home'));

const App = () => (
  <Provider store={store}>
    <GlobalProvider>
      <Router>
        <Header />
        <main className="mainBody">
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/add" element={<Add />} />
              <Route path="/test" element={<Test />} />
              <Route path="/register" element={<Register />} />
              <Route path="/post_details/:postId" element={
                <ProtectedRoute>
                  <PostDetails />
                </ProtectedRoute>
              } />
              <Route path="/persona_details/:personaId" element={
                <ProtectedRoute>
                  <PersonaDetails />
                </ProtectedRoute>
              } />
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </Router>
    </GlobalProvider>
  </Provider>
);

export default App;
