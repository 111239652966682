import React, { useState, useEffect } from 'react';
import axiosInstance from "../../utils/axiosInstance";
import { useAuth } from "../AuthContext";

import replyBlack from '../../asset/comments/reply_black.svg';
import replyWhite from '../../asset/comments/reply_white.svg';
import replyIconActive from '../../asset/comments/replyIconActive.svg';

import burnBlack from '../../asset/comments/burn_black.svg';
import burnWhite from '../../asset/comments/burn_white.svg';
import burnIconActive from '../../asset/comments/burnIconActive.svg';

import upArrowBlack from '../../asset/comments/up_arrow_black.svg';
import upArrowWhite from '../../asset/comments/up_arrow_white.svg';
import upArrowIconActive from '../../asset/comments/upArrowIconActive.svg';

import downArrowBlack from '../../asset/comments/down_arrow_black.svg';
import downArrowWhite from '../../asset/comments/down_arrow_white.svg';
import downArrowIconActive from '../../asset/comments/downArrowIconActive.svg';

import moreBlack from '../../asset/comments/more_black.svg';
import moreWhite from '../../asset/comments/more_white.svg';
import moreIconActive from '../../asset/comments/moreIconActive.svg';

import profilePic from '../../assets/profilePic.png';

import ReplyList from './ReplyList';
import ReplyForm from './ReplyForm';
import HoverIcon from "../../utils/HoverIcon";

import flag from '../../asset/flag.svg';
import shareBlack from '../../asset/share_black.svg';

function CommentSection({ postId }) {
    const { userDetails } = useAuth();
    const [comments, setComments] = useState([]);

    const [ID, setID] = useState(false);
    const [showReplyForm, setShowReplyForm] = useState(false);
    const [showReplyList, setShowReplyList] = useState(false);
    const [showFlag, setShowFlag] = useState(false);
    const [call, setCall] = useState(false);

    const handleShowReplyForm = (commentId) => {
        setID(commentId);
        setShowReplyForm(prevState => !prevState);
        setShowReplyList(false);
    };

    const handleShowReplyList = (commentId) => {
        setID(commentId);
        setShowReplyList(prevState => !prevState);
        setShowReplyForm(false);
    };

    const handleFlag = (commentId) => {
        setID(commentId);
        setShowFlag(prevState => !prevState);
    };

    useEffect(() => {
        getCommentsList();
    }, [postId, call]);

    const getCommentsList = async () => {
        let prmData = {
            postuniqueid: postId
        };
        try {
            const response = await axiosInstance.post('comments/select', prmData);
            setComments(response.data);
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    const addLikes = async (postcommentuniqueid, islike) => {
        let prmData = {
            userid: userDetails.companyUserUniqueId,
            postid: postId,
            commentid: postcommentuniqueid,
            islike: islike
        };
        try {
            const response = await axiosInstance.post('likes/insert', prmData);
            setCall(prevState => !prevState);
        } catch (error) {
            console.error('There was an error making the request!', error);
        }
    };

    return (
        <div className='mx-[5px]'>
            {comments.map((eachData, index) => (
                <div className='flex flex-col gap-[5px]' key={index}>
                    <div className='flex items-center gap-[5px]'>
                        <img src={profilePic} alt="" className='w-[15px] h-[15px]' />
                        <h5 className='font-500'>{eachData.username}</h5>
                        <h6 className='font-100'>{eachData.timeago}</h6>
                    </div>
                    <div>
                        <h4 className='font-400 ml-[20px]'>{eachData.comments}</h4>
                    </div>
                    <div className='flex items-center gap-4 ml-[20px]'>
                        <HoverIcon
                            defaultIconBlack={replyBlack}
                            defaultIconWhite={replyWhite}
                            activeIcon={replyIconActive}
                            altText="Reply"
                            onClick={() => handleShowReplyForm(eachData.postcommentuniqueid)}
                        />
                        {eachData.noofreplies > 0 &&
                            <div className='text-[#0000001A] dark:text-[#FFFFFF1A] hover:text-[#4169E1] font-bold cursor-pointer' onClick={() => handleShowReplyList(eachData.postcommentuniqueid)}>{eachData.noofreplies}R</div>
                        }
                        <HoverIcon
                            defaultIconBlack={burnBlack}
                            defaultIconWhite={burnWhite}
                            activeIcon={burnIconActive}
                            altText="Burn"
                        />
                        <HoverIcon
                            defaultIconBlack={upArrowBlack}
                            defaultIconWhite={upArrowWhite}
                            activeIcon={upArrowIconActive}
                            altText=""
                            onClick={() => addLikes(eachData.postcommentuniqueid, true)}
                        />
                        <div className='text-[#0000001A] dark:text-[#FFFFFF1A] hover:text-[#4169E1] font-bold cursor-pointer'>{eachData.nooflikes}</div>
                        <HoverIcon
                            defaultIconBlack={downArrowBlack}
                            defaultIconWhite={downArrowWhite}
                            activeIcon={downArrowIconActive}
                            altText=""
                            onClick={() => addLikes(eachData.postcommentuniqueid, false)}
                        />
                        <HoverIcon
                            defaultIconBlack={moreBlack}
                            defaultIconWhite={moreWhite}
                            activeIcon={moreIconActive}
                            altText=""
                            onClick={() => handleFlag(eachData.postcommentuniqueid)}
                        />
                    </div>

                    {showReplyForm && (ID === eachData.postcommentuniqueid) && (
                        <ReplyForm data={eachData} type="reply" getCommentsList={getCommentsList} />
                    )}

                    {showReplyList && (ID === eachData.postcommentuniqueid) && (
                        <ReplyList data={eachData} type="reply" />
                    )}

                    {showFlag && (ID === eachData.postcommentuniqueid) && (
                        <div>
                            <div className='w-48 flex justify-between'>
                                <img src={flag} alt="" />
                                <div className='w-full border'></div>
                                <div className='btnFlag'>Flag</div>
                            </div>
                            {/* <img src={shareBlack} alt="" /> */}
                        </div>
                    )}
                </div>
            ))}
        </div>
    );
}

export default CommentSection;
