import React from 'react';
import profilePic from '../assets/profilePic.png';
import commentBlack from '../asset/post/comment_black.svg';
import commentWhite from '../asset/post/comment_white.svg';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import BubbleBlue from './BubbleBlue';

function Post({ post }) {
  const { isAuthenticated } = useAuth();

  const setBoxShadow = (value) => {
    switch (value) {
      case 'intelligent':
        return 'postCard post intelligentBorder';
      case 'stupid':
        return 'postCard post stupidBorder';
      case 'helpless':
        return 'postCard post helplessBorder';
      case 'bandit':
        return 'postCard post banditBorder';
      default:
        return 'postCard post neutralBorder';
    }
  };
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (isAuthenticated) {
      navigate(`/post_details/${post.postuniqueid}`);
    }
    else {
      alert("Please login")
    }
  };
  return (
    <div className={setBoxShadow(post.votetype)}>
      <div className='flex justify-end items-center gap-1'>
        <div>
          {(post.username == null || post.username == '') &&
            <h5 className='font-400 text-right'>anonymous</h5>
          }
          {(post.username != null || post.username != '') &&
            <h5 className='font-400 text-right'>{post.username}</h5>
          }
          <h6 className='font-100 text-right'>{post.time_ago}</h6>
        </div>
        <div>
          <img src={profilePic} alt="profilePic" />
        </div>
      </div>
      <div onClick={handleNavigation}>
        <div className='flex items-center gap-[10px]'>
          <img src={`${process.env.REACT_APP_IMAGE_URL}${post.postimage}`} alt="..." className='w-[30px] h-[40px]' />
          <h3 className='font-400'>{post.postname}</h3>
        </div>
        <div className='flex justify-between items-center'>
          <div className='flex items-center gap-1'>
            <div>
              <img src={commentWhite} className='darkMode' />
              <img src={commentBlack} className='lightMode' />
            </div>
            <h6 className='font-100'>{post.noofcomments} comments</h6>
          </div>
          <div className='flex items-center gap-1'>
            <h6 className='font-100'>{post.noofvotes} votes</h6>
            <BubbleBlue data={post.data} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Post;
