import React from "react";
import sendBlack from '../asset/comments/send_black.svg';
import sendWhite from '../asset/comments/send_white.svg';

import searchBlack from '../asset/search_black.svg';
import searchWhite from '../asset/search_white.svg';
import greenDot from '../asset/green_dot.svg';

function Register() {
  return (
    <div className="h-full p-[5px] pb-16 flex flex-col gap-2 ">
      <div className="loginCard registrationCard">
        <input type="text" className="inputField" placeholder="User ID" />
        <input type="password" className="inputField" placeholder="Password" />
        <h4>by registering you agree to the terms and conditions</h4>

        <div className="flex justify-between items-center">
          <h5 className="font-300">cancel</h5>
          <img src={sendWhite} alt="" className="darkMode" />
          <img src={sendBlack} alt="" className="lightMode" />
        </div>
      </div>
      <div className="registrationCard">
        <input type="text" className="inputField" placeholder="Email ID" />
        <input type="password" className="inputField" placeholder="Password" />
        <input
          type="text"
          className="inputField"
          placeholder="Confirm Password"
        />
        <h4>by registering you agree to the terms and conditions</h4>

        <div className="flex justify-between items-center">
          <h5 className="font-300">cancel</h5>
          <img src={sendWhite} alt="" className="darkMode" />
          <img src={sendBlack} alt="" className="lightMode" />
        </div>
      </div>
      <div className="registrationCard">
        <input type="text" className="inputField" placeholder="Google ID" />
        <h4>by registering you agree to the terms and conditions</h4>
        <div className="flex justify-between items-center">
          <h5 className="font-300">cancel</h5>
          <img src={sendWhite} alt="" className="darkMode" />
          <img src={sendBlack} alt="" className="lightMode" />
        </div>
      </div>
      
      <div className="registrationCard">
        <h4>Registration Successful!</h4>
        <div className="flex flex-col gap-[2px]">
          <label htmlFor="avatarName">
            <h4>Name your avatar</h4>
          </label>
          <input
            type="text"
            id="avatarName"
            className="inputField w-full"
            placeholder="maybe_one_eyed_joe!"
          />
          <h5 className="font-300 text-end">We advocate anonymity.</h5>
          <h5 className="font-300 underline text-end">Why?</h5>
        </div>
        <div className="flex justify-between items-center">
          <h5 className="font-300">skip</h5>
          <img src={sendWhite} alt="" className="darkMode" />
          <img src={sendBlack} alt="" className="lightMode" />
        </div>
      </div>
      <div className="registrationCard">
        <h5>
          Anonymity would promote impersonal conversations, reduce ad hominem
          attacks. Learn more...
        </h5>
      </div>
      <div className="registrationCard">
        <h4 className="font-bold">Country :</h4>
        <div className="flex flex-col gap-[2px]">
          <h4>Use address to find your representatives</h4>
          <input
            type="text"
            className="inputField w-full"
            placeholder="1000, Bank st, Richmond, VA 23218"
          />
          <h5 className="font-300 text-end">
            Address is stored & used in API to look up representative.
          </h5>
          <h5 className="font-300 underline text-end">How?</h5>
        </div>
        <div className="flex justify-between">
          <h5 className="font-300">Skip</h5>
          <img src={sendWhite} alt="" className="darkMode" />
          <img src={sendBlack} alt="" className="lightMode" />
        </div>
      </div>
      <div className="registrationCard">
        <h5>
          Address is input to Google API to fetch a list of available local,
          state & federal representatives. It is encrypted and stored for future
          election updates. Learn more...
        </h5>
      </div>
      {/*  */}
      <div className="contactCard">
        <div className="relative">
          <input type="text" className="inputField !pl-[25px] w-full" placeholder="Whom?" />
          <div className="absolute top-[4px] left-[5px]">
            <img src={searchWhite} alt="" className="darkMode" />
            <img src={searchBlack} alt="" className="lightMode" />
          </div>
        </div>
        <input type="text" className="inputField" placeholder="Post title max 250 chars" />
        <input type="text" className="inputField" placeholder="URL" />

        <div className="flex justify-between items-center">
          <h5 className="font-300"></h5>
          <img src={sendWhite} alt="" className="darkMode" />
          <img src={sendBlack} alt="" className="lightMode" />
        </div>
      </div>

      <div className="w-full sm:w-[310px] relative">
        <input type="text" className="inputField !h-10 w-full" placeholder="Add your comment" />
        <div className="absolute top-[10px] right-[15px] pl-[10px]">
          <img src={sendWhite} alt="" className="darkMode" />
          <img src={sendBlack} alt="" className="lightMode" />
        </div>
      </div>

      <div className="w-full relative py-5 px-[5px]">
        <input type="text" className="inputField searchField !h-10 w-full pl-[30px]" placeholder="Whom?" />
        <div className="absolute top-[32px] left-[15px]">
          <img src={searchWhite} alt="" className="darkMode" />
          <img src={searchBlack} alt="" className="lightMode" />
        </div>
      </div>

      <div className="flex flex-col w-full lg:w-[320px] p-[10px] gap-[10px] bg-[#E5E5EF]">
        <div className="flex items-center gap-[10px]">
          <div className="w-full">
            <div className="flex justify-between">
              <h4 className="font-700">Reply to your comment</h4>
              <h4 className="font-200">30 minutes ago</h4>
            </div>
            <h4 className="">maybe_fredirick replied to your comment in post “Etiam fermentum felis urna</h4>
          </div>
          <div>
            <img src={greenDot} alt="" />
          </div>
        </div>
        <div className="flex items-center gap-[10px]">
          <div className="w-full">
            <div className="flex justify-between">
              <h4 className="font-700">New comment on your post</h4>
              <h4 className="font-200">3 hours ago</h4>
            </div>
            <h4 className="">maybe_fredirick replied to your comment in post “Etiam fermentum felis urna</h4>
          </div>
          <div>
            <img src={greenDot} alt="" />
          </div>
        </div>
        <div className="flex items-center gap-[10px]">
          <div className="w-full">
            <div className="flex justify-between">
              <h4 className="font-700">Politician added</h4>
              <h4 className="font-200">3 days ago</h4>
            </div>
            <h4 className="">Obama has been successfully added</h4>
          </div>
          <div>
            <img src={greenDot} alt="" />
          </div>
        </div>
      </div>

      <div className="flex justify-between items-center w-full lg:w-[320px] p-[10px_15px_10px_5px] bg-[#E5E5EF]">
        <h4 className="font-700">87 Votes remain</h4>
        <button className="btnBlue w-[73px]">Buy more</button>
      </div>
      <div className="w-full lg:w-[154px] h-[24px] rounded-[5px] bg-[#E5E5EF] flex justify-between items-center pl-[10px]">
        <h1>My Bunch</h1>
        <button className="addButton hover:btnBlue w-[48px]">Add</button>
      </div>
    </div>
  );
}

export default Register;
