import React, { useEffect } from 'react';
import AddPersona from './../components/admin/AddPersona';
import AddPost from './../components/admin/AddPost';

function Add() {

    useEffect(() => {
        // 
    }, []);

    return (
        <div>
            <AddPersona />
            <AddPost />
        </div>
    );
}

export default Add;
